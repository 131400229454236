export default [
    '$timeout',
    '$state',
    '$scope',
    'pageService',
    'Flash',
    'taskData',
    'pageData',
    'unitData',
    function (
        $timeout,
        $state,
        $scope,
        pageService,
        Flash,
        taskData,
        pageData,
        unitData
    ) {
        $scope.$on('selection', (event, area) => {
            $scope.$broadcast('selectedPost', {
                pageData: $scope.viewer.page,
                area: area
            })
        })

        $scope.viewer = {
            zoomedPost: undefined,
            selectedPost: pageData.task_page.is_done === 1 ? false : pageData.next_post,
            page: pageData,
            showEditPost: true
        }

        $scope.pageNumber = pageData.page_number;
        $scope.totalPages = unitData.pages;
        
        // If the page is marked as done, do not show selection, eventhough next_post might contain a valid position overlay object
        $scope.selectedPost = pageData.task_page.is_done === 1 ? false : pageData.next_post
    
        // //Openseadragon does not like a property called id, as it tries to find the dom node with that id.
        // //Hack to rename the property, backend should do this
        // $scope.overlays = [];
        // for (let post of pageData.posts) {
        //     var obj = {
        //         className: post.className,
        //         complete:post.complete,
        //         height: post.height,
        //         pages_id: post.pages_id,
        //         postId: post.id,
        //         width: post.width,
        //         x: post.x,
        //         y: post.y,
        //         canEdit: post.user_can_edit
        //     };
        //     $scope.overlays.push(obj);
        // };

        $scope.zoomIn = function() {
            $scope.$broadcast('zoomIn')
        }

        $scope.zoomOut = function() {
            $scope.$broadcast('zoomOut')
        }

        $scope.setViewerPages = function(pages) {
            $scope.viewer.pages = pages
        }

        /**
        * Determine if the task this page belongs to is done ie. all pages typed
        */
        $scope.isDone = function() {
            //Loop over all tasks, and find the one that match the one we are working on
            var thisTask = unitData.tasks.find(function(task) {
                return task.tasks_id === taskData.id;
            });

            //Are we done?
            return thisTask.index_active === 0 ? true : false;
        };

        /**
        * Get next available page, based on unitId, taskId and the current page number
        */
        $scope.goToNextAvailablePage = function() {
            pageService.getNextAvailablePage({
                task_id: taskData.id,
                unit_id: pageData.unit_id,
                current_number: pageData.page_number
            }).then(function(response) {
                if(response.pages_id){
                    $timeout(function() {
                        $state.go('taskunit.page.new', { taskId: taskData.id, unitId: unitData.id, pageId: response.pages_id});
                    }, 0);
                }
            });
        };
    }
];