var pageFooterController = [
    '$state',
    '$scope',
    'pageService',
    'Flash',
    'taskData',
    'unitData',
    'pageData',
    function($state, $scope, pageService, Flash, taskData, unitData, pageData) {
        $scope.pageNumber = pageData.page_number;
        $scope.totalPages = unitData.pages;

        /**
         * Determine if the task this page belongs to is done ie. all pages typed
         */
        $scope.isDone = function() {
            //Loop over all tasks, and find the one that match the one we are working on
            var thisTask = unitData.tasks.find(function(task) {
                return task.tasks_id === taskData.id;
            });
    
            //Are we done?
            return thisTask.index_active === 0 ? true : false;
        };

        /**
         * Get next available page, based on unitId, taskId and the current page number
         */
        $scope.goToNextAvailablePage = function() {
            pageService.getNextAvailablePage({
                task_id: taskData.id,
                unit_id: pageData.unit_id,
                current_number: pageData.page_number
            })
            .then(function(response) {
                if (response.pages_id) {
                    $timeout(function() {
                        $state.go('^.new', { pageId: response.pages_id });
                    }, 0);
                }
            });
        }
    
        $scope.nextPage = function() {
            var pageNumber = parseInt(pageData.page_number) + 1,
                unitId = pageData.unit_id;
    
            pageService.getPageByNumber(pageNumber, unitId)
            .then(function(response) {
                $state.go('^.new', { unitId: unitId, pageId: response.id });
            })
            .catch(function() {
                Flash.create('warning', 'Siden med nummer ' + pageNumber +  ' findes ikke');
            });
        };

        $scope.goToPage = function($event) {
            //Enter key
            if ($event.which === 13) {
                var pageNumber = $event.target.value,
                    unitId = pageData.unit_id;

                pageService.getPageByNumber(pageNumber, unitId)
                .then(function(pageInfo) {
                    $state.go('^.new', {
                        pageId: pageInfo.id
                    });
                })
                .catch(function() {
                    Flash.create('warning', 'Siden med nummer ' + pageNumber +  ' findes ikke');
                });
            }
        };

        $scope.prevPage = function() {
            var pageNumber = parseInt(pageData.page_number) - 1,
                unitId = pageData.unit_id;

            if (pageNumber === 0) {
                Flash.create('warning', 'Du er på første side');
                return;
            }

            pageService.getPageByNumber(pageNumber, unitId)
            .then(function(pageInfo) {
                $state.go('^.new', {
                    pageId: pageInfo.id
                });
            })
            .catch(function() {
                Flash.create('warning', 'Siden med nummer ' + pageNumber +  ' findes ikke');
            });
        };
    }
];

export default pageFooterController;
