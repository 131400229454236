import angular from "angular";
export default angular.module("constants", [])
  .constant("EDITOR_URL", "https://kildetaster.kbharkiv.dk")
  .constant("CALLBACK_URL", "https://kildetaster.kbharkiv.dk/#/callback")
  .constant("RESOURCE_URL", "https://kildetaster.kbharkiv.dk/resources/")
  .constant("API_URL", "https://api.kbharkiv.dk")
  .constant("SOLR_API_URL", "https://aws.kbhkilder.dk/solr/apacs_core/select")
  .constant("SEARCH_CONFIG_URL", "https://api.kbharkiv.dk/searchconfig")
  .constant("ERROR_REPORT_CONFIG_URL", "https://api.kbharkiv.dk/errorreportconfig")
  .constant("MAIN_DOMAIN", "https://www.kbharkiv.dk")
  .constant("ERROR_URL", "https://www.kbharkiv.dk/deltag/indtast-data-fra-arkivmateriale")
  .constant("PERMALINK_URL", "https://kbharkiv.dk/permalink")
  .constant("AUTH0_CLIENTID", "uNrqzxblFnPrzQWpqMMBiB8h0VppBesM")
  .constant("AUTH0_DOMAIN", "login.kbharkiv.dk")
  .constant("AUTH0_AUDIENCE", "https://www.kbhkilder.dk/api")
  .constant("TYPEAHEAD_MAX", 75)
  .constant("IDLE_REFRESH_SECONDS", 300);
