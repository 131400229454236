export default [
    'MAIN_DOMAIN',
    '$scope',
    'taskData',
    'unitData',
    'userData',
    'userService',
    function (
        MAIN_DOMAIN,
        $scope,
        taskData,
        unitData,
        userData,
        userService
    ) {
        $scope.protocol = taskData.name;
        $scope.progress = Math.round(taskData.pagesLeft / taskData.pagesTotal * 100);
        $scope.pagesTotal = unitData.pages;
        $scope.pagesDone = unitData.tasks.find(function(data) {
            return data.tasks_id === taskData.id;
        }).pages_done;
        $scope.activeUsers = [];
        $scope.activeUser = userData;
        $scope.MAIN_DOMAIN = MAIN_DOMAIN;
        $scope.unit = unitData;
        $scope.logoUrl = require('../../../images/ugle.gif').default;
        $scope.selectionImageUrl = require('../../../images/Cursor-Select-512.png').default;;
    
        userService.getUsers(unitData.id, taskData.id).then(function(response) {
            $scope.activeUsers = response;
        });
        setInterval(function() {
            userService.getUsers(unitData.id, taskData.id).then(function(response) {
                $scope.activeUsers = response;
            });
        }, 1000 * 60 * 5)
    }
];