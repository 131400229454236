import angular from 'angular';
import Clipboard from 'clipboard';

export default [
    '$uibModal',
    '$state',
    '$timeout',
    '$http',
    '$scope',
    '$rootScope',
    '$transitions',
    'API_URL',
    'PERMALINK_URL',
    'helpers',
    'postService',
    'stepService',
    'pageData',
    'unitData',
    'taskData',
    'pageService',
    'wizardShare',
    function (
        $uibModal,
        $state,
        $timeout,
        $http,
        $scope,
        $rootScope,
        $transitions,
        API_URL,
        PERMALINK_URL,
        helpers,
        postService,
        stepService,
        pageData,
        unitData,
        taskData,
        pageService,
        wizardShare
    ) {
        // Keep track of the last valid step that has been naviated to
        // and only allow navigation to the next step, or to any preceding step
        let lastValidStep = 1

        //Indicates if we should show the controls for accepting a new area (used on all other steps than the first)
        $scope.showSelectionControls = false;
        $scope.steps = [];
        $scope.numSteps = null;
        $scope.currentStep = $state.params.stepId || 1;
        $scope.wantFeedback = false;
        $scope.shareLink = '';
        $scope.shareLinkId = undefined;

        $scope.allow_subposts = taskData.allow_subposts

        //Will hold the inputted values
        $scope.values = {};

        //Object to keep track of what fields is currently marked as being editied
        $scope.editingField = '';
        $scope.singleFieldForms = {};
        $scope.singleSchema = {};
        $scope.singleValue = {};

        //Default settings for angular-schema-forms
        $scope.sfDefaults = {
            formDefaults: {
                feedback: false,
                supressPropertyTitles: true,
                disableSuccessState: true,
                ngModelOptions: {
                    updateOn: 'blur'
                }
            }
        };
        $scope.wizardShare = wizardShare
        $scope.viewer.showEditPost = false

        $scope.selectionState = false
        $scope.selectionSubpostIndex = undefined
        let prevZoomedPost = undefined
        $scope.editMainPost = function () {
            if ($scope.selectionState !== false) {
                $scope.cancelPostSelection()
            }
            prevZoomedPost = $scope.wizardShare.zoomedPost
            $scope.wizardShare.zoomedPost = undefined

            if ($scope.viewer.page.id != $scope.wizardShare.main.pageData.id) {
                $scope.viewer.page = $scope.wizardShare.main.pageData
            }

            broadcastPostOverlays();
            $scope.viewer.selectedPost = $scope.wizardShare.main.area

            $scope.selectionState = 'editMainPost'
        }

        $scope.addSubpost = function () {
            if ($scope.selectionState !== false) {
                $scope.cancelPostSelection()
            }
            prevZoomedPost = $scope.wizardShare.zoomedPost
            $scope.wizardShare.zoomedPost = undefined
            $scope.selectionState = 'addSubpost'

            let selectedPost = undefined
            if ($scope.wizardShare.subposts) {
                let currentPageSubposts = $scope.wizardShare.subposts.filter(sp => sp.pageData.id == $scope.viewer.page.id)
                if (currentPageSubposts.length > 0) {
                    selectedPost = currentPageSubposts[currentPageSubposts.length - 1]
                }
            }

            if (!selectedPost) {
                $scope.viewer.selectedPost = $scope.wizardShare.main.area
            } else {
                $scope.viewer.selectedPost = selectedPost.area
            }
        }

        $scope.editSubpost = function (index) {
            if ($scope.selectionState !== false) {
                $scope.cancelPostSelection()
            }
            prevZoomedPost = $scope.wizardShare.zoomedPost
            $scope.wizardShare.zoomedPost = undefined

            let subpost = $scope.wizardShare.subposts[index]
            if ($scope.viewer.page.id != subpost.pageData.id) {
                $scope.viewer.page = subpost.pageData
            }
            broadcastPostOverlays();
            $scope.viewer.selectedPost = subpost.area

            $scope.selectionState = 'editSubpost'
            $scope.selectionSubpostIndex = index
        }

        $scope.cancelPostSelection = function () {
            $scope.viewer.selectedPost = undefined
            $scope.selectionState = false
            $scope.selectionSubpostIndex = undefined
            $scope.wizardShare.zoomedPost = prevZoomedPost
            broadcastPostOverlays();
        }

        $scope.removeSubpost = function (index) {
            let subpost = $scope.wizardShare.subposts.splice(index, 1)[0]

            let zoomedPostAfter
            if (prevZoomedPost && prevZoomedPost != subpost.area) {
                // Go to previous zoomed post
                zoomedPostAfter = prevZoomedPost
            } else if (index > 0) {
                // Find the previous subpost in the order of added
                zoomedPostAfter = $scope.wizardShare.subposts[index - 1]
            } else {
                // Fall back to main area
                zoomedPostAfter = $scope.wizardShare.main
            }

            if ($scope.wizardShare.zoomedPost == subpost) {
                // only change zoomed post if current is the delete subpost
                $scope.wizardShare.zoomedPost = zoomedPostAfter
                prevZoomedPost = undefined // undefine prevZoomedPost as this one is removed
            }
        }

        $scope.acceptPost = function () {
            $rootScope.$broadcast('areaAccepted');
        }

        /**
         * The zoomed post has been selected on the wizard footer
         */
        $scope.$watch('wizardShare.zoomedPost', function (newVal, oldVal) {
            if ($scope.wizardShare.zoomedPost) {
                $scope.viewer.zoomedPost = $scope.wizardShare.zoomedPost.area
                $scope.viewer.page = $scope.wizardShare.zoomedPost.pageData
            } else {
                $scope.viewer.zoomedPost = undefined
            }
            broadcastPostOverlays();
        })

                
        $scope.goToPageNumber = function (pageNumber) {
            if (pageNumber < 1 || pageNumber > unitData.pages || pageNumber == $scope.viewer.page.page_number) {
                return
            }

            pageService.getPageDataByNumber(taskData.id, pageNumber, unitData.id).then(function(response) {
                if (!angular.isArray(response)) {
                    $scope.wizardShare.zoomedPost = undefined
                    $scope.viewer.zoomedPost = undefined
                    $scope.viewer.page = response
                    broadcastPostOverlays();
                }
            });
        }

        /**
         * An area/post was selected by the image viewer.
         */
        $scope.$on('selectedPost', function (event, postData) {
            if ($scope.wizardShare.main === undefined) {
                $scope.wizardShare.main = postData
                $scope.viewer.zoomedPost = postData.area
                $scope.wizardShare.zoomedPost = postData
            } else if ($scope.selectionState == 'editMainPost') {
                $scope.wizardShare.main = postData
                $scope.viewer.zoomedPost = postData.area
                $scope.wizardShare.zoomedPost = postData
            } else if ($scope.selectionState === 'addSubpost') {
                $scope.wizardShare.subposts.push(postData)
                $scope.viewer.zoomedPost = postData.area
                $scope.wizardShare.zoomedPost = postData
            } else if ($scope.selectionState === 'editSubpost') {
                $scope.wizardShare.subposts[$scope.selectionSubpostIndex] = postData
                $scope.selectionSubpostIndex = undefined
                $scope.viewer.zoomedPost = postData.area
                $scope.wizardShare.zoomedPost = postData
            }

            $scope.selectionState = false
        })

        $scope.broadcastPostOverlays = broadcastPostOverlays

        function broadcastPostOverlays() {
            $timeout(function () {
                let postOverlaysData = { subposts: [] }

                if ($scope.wizardShare.main != $scope.wizardShare.zoomedPost) {
                    postOverlaysData.main = $scope.wizardShare.main
                }
    
                for (let subpost of $scope.wizardShare.subposts) {
                    if (subpost != $scope.wizardShare.zoomedPost) {
                        postOverlaysData.subposts.push(subpost)
                    }
                }
                $rootScope.$broadcast('postOverlays', postOverlaysData)
            }, 500)
        }

        /**
        * Build the value structure for single fields, that is fields that are part of an array structure
        */
        $scope.getValue = function (key, subkey, id) {
            var valueInValues = $scope.values[$scope.mainProperty][key];

            var data = valueInValues[id];

            $scope.singleValue = data;
        };

        /**
        * Build schema for single fields, that is fields that are part of an array structure
        */
        $scope.getSchema = function getSchema(key, subkey) {
            var data = {
                type: 'object',
                properties: {}
            };

            data.properties[subkey] = $scope.schema.properties[$scope.mainProperty].properties[key].items.properties[subkey];

            $scope.singleSchema = data;
        };

        
        let deregisterOnBeforeHook = $transitions.onBefore({ from: 'taskunit.page.wizard', to: 'taskunit.page.wizard' }, function (transition) {       
            // Return early if step data is not yet set, this is to allow the 
            // redirect from the init function
            if ($scope.currentStepData === undefined && transition.params().stepId === 1) {
                return true
            }

            // Validate the form
            if ($scope.currentStepData.fields !== undefined) {
                $scope.$broadcast('schemaFormValidate');

                // If the form is not valid, prevent changing the location variable and thus
                // going to the next step
                if ($scope.stepForm) {
                    if ($scope.stepForm.$invalid) {
                        lastValidStep = $scope.currentStep - 1

                        // Allow going to a previous step even though the form is not validated
                        return transition.params().stepId < $scope.currentStep;
                    } else {
                        lastValidStep = Math.max(lastValidStep, $scope.currentStep)
                    }
                } else {
                    // TODO: hack to fix weird bug in step transitions
                    console.warn('allowed transition to step without validation of form');
                    return true
                }
            }

            // disallow movement to steps beyond the one after the last valid step
            if (transition.params().stepId > lastValidStep + 1) {
                return transition.router.stateService.target('taskunit.page.wizard', { stepId: lastValidStep + 1 })
            }

            return true
        });

        // Hook for transitions moving away from the wizard, to clean up page controller state
        let deregisterOnStartHook = $transitions.onStart({
            from: 'taskunit.page.wizard',
            to: function (state) { return state.name != 'taskunit.page.wizard' }
        }, function () {
            $scope.viewer.selectedPost = pageData.task_page.is_done === 1 ? false : pageData.next_post
            $scope.viewer.page = pageData
            $scope.viewer.zoomedPost = null
            $scope.viewer.showEditPost = true
            $rootScope.$broadcast('resetOverlays');
        })

        /* Deregister the transitionService hooks on destroy. */
        $scope.$on('$destroy', function() {
            deregisterOnBeforeHook();
            deregisterOnStartHook();
        })

        /**
         * Set proper focus when the schemaform is rendered.
         */
        $scope.$on('sf-render-finished', function (ev, arg) {
            $timeout(function () {
                if ($scope.currentStepData.type == 'save') {
                    // BEGIN HACK
                    // For some reason, empty objects are created in some arrays for task 8
                    // I have narrowed it down to happening between the sf-render-finished
                    // event being emitted, and this timeout function being called, but due
                    // to lack of time, I cannot figure out why or where.
                    // Hence, we check for and remove them here.
                    if (taskData.id == 8) {
                        $scope.values.event.parents = $scope.values.event.parents.filter(function(person) {
                            var clone = structuredClone(person);
                            delete clone.$$hashKey;
                            return JSON.stringify(clone) != '{"is_deceased":false,"vielser_person_role":[{}],"parent_occupations":[{}]}';
                        });
                        $scope.values.event.others = $scope.values.event.others.filter(function(person) {
                            var clone = structuredClone(person);
                            delete clone.$$hashKey;
                            return JSON.stringify(clone) != '{"vielser_person_role":[{}],"other_occupations":[{}]}';
                        });
                    }
                    // END HACK
                    $('#save-button').focus();
                } else if ($scope.currentStepData.type == 'posts') {

                } else {
                $timeout(function () {
                    var field = $('*').find(':input:not(input[type=button],input[type=submit],button):visible:first').first();
                    if (field == undefined) {
                        field = $('*').find('.add-button').first();
                    }
                    if (field == undefined) {
                        field = $('*').find('.next-button').first();
                    }
                    $timeout(function() {
                        field.focus();
                    });
                });
            }
            });
        });

        /**
         * Because we do not trigger the ui.route logic (see.editor.config.js),
         * listen for changes to the location.search
         */
        $transitions.onSuccess({ from: 'taskunit.page.wizard', to: 'taskunit.page.wizard' }, function () {
            if ($state.params.stepId == $scope.currentStep) {
                return false;
            }

            //Make sure we treat currentStep value as an integer
            $scope.currentStep = $state.params.stepId;

            $scope.currentStepData = $scope.steps[$scope.currentStep - 1];
        });

        $scope.acceptArea = function acceptArea() {
            $rootScope.$broadcast('areaAccepted');
        };

        //http://stackoverflow.com/questions/24081004/angularjs-ng-repeat-filter-when-value-is-greater-than
        //predicate for filter in template
        // Will return true if the field is either empty/null or is marked unreadable
        $scope.hasValue = function hasValue(prop) {
            return function (item) {
                return (item[prop] !== undefined && item[prop] !== '') || item.unreadable;
            };
        };


        /**
         * Toggle wether or not we should show edit field for a given field config
         */
        $scope.toggleEditExistingValue = function toggleEditExistingValue(item) {
            if ($scope.editingField === item) {
                $scope.editingField = '';
            } else {
                $scope.editingField = item;
            }

        };

        $scope.closeEditField = function closeEditField() {
            $scope.editingField = '';
        };

        /**
         * Ask if a given field is currently being edited
         */
        $scope.isEditing = function isEditing(field) {
            return $scope.editingField == field;
        };

        /**
        * Save the entry in the backend
        */
        $scope.save = function save() {
            $scope.saving = true;
            return ($scope.wizardShare.zoomedPost.area.id ? postService.update(taskData.id, angular.merge({ page_id: $scope.wizardShare.main.pageData.id }, $scope.wizardShare.main.area), $scope.wizardShare.zoomedPost.area.id) : postService.create(taskData.id, angular.merge({ page_id: $scope.wizardShare.main.pageData.id }, $scope.wizardShare.main.area)))
                .then(function (response) {
                    if (taskData.id != 4) {
                        // skip subposts if not task 4
                        return response
                    }

                    let subposts = []
                    for (let subpost of $scope.wizardShare.subposts) {
                        subposts.push(angular.merge({
                            pages_id: subpost.pageData.id
                        }, subpost.area))
                    }
                    return postService.createSubposts(taskData.id, response.data.post_id, subposts)
                        .then(function () {
                            return response
                        })
                })
                .then(function (response) {
                    // Chain the saving of the entry itself
                    return $http({
                        method: 'POST',
                        url: API_URL + '/entries/',
                        data: angular.merge({
                            task_id: taskData.id,
                            page_id: pageData.id,
                            post_id: response.data.post_id,
                        }, $scope.values),
                        authorizeRequest: true
                    })
                })
                .then(function (response) {
                    //If the reqeust was ok from the server, assume everything is allright
                    $scope.entrySaved = true;
                    $scope.shareLinkId = response.data.solr_id;

                    //Continue Step, so focus on continue button
                    $timeout(function () {
                        $('#done-button').focus();
                    });

                    return { status: 'ok' }
                })
                .catch(function (err) {
                    $scope.error = err;

                    $uibModal.open({
                        template: require('./error.modal.tpl.html'),
                        //The type of modal. The error modal makes more room for the error text
                        windowClass: 'modal--error',

                        //Make wizard scope available to the modal
                        scope: $scope,

                        controller: ['$scope', function ($scope) {
                            $scope.dismiss = function () {
                                $scope.$dismiss();
                            };
                        }]
                    });

                    return { status: 'error' }
                })
                .finally(function () {
                    $scope.saving = false;
                })
        };

        $scope.getObjectStringRepresentation = function (data, schema) {
            return helpers.getObjectStringRepresentation(data, schema)
        };

        /**
         * Move to next step
         */
        $scope.nextStep = function nextStep() {
            var newStep = $scope.currentStep + 1;

            $state.go('.', { stepId: newStep });
        };

        /**
         * Move to previous step
         */
        $scope.prevStep = function prevStep() {
            var newStep = $scope.currentStep - 1;

            //If we are step 1, return to outside the form, and restore selectable area
            if ($scope.currentStep === 1) {
                $state.go('^.new');
                return
            }

            //Validate the form
            $scope.$broadcast('schemaFormValidate');

            //If the current step is not valid, prevent going to previous step
            if ($scope.stepForm.$invalid) {
                return;
            }

            //Update the search variable
            $state.go('.', { stepId: newStep });
        };

        $scope.goToStep = function goToStep(stepId) {
            $state.go('.', { stepId: stepId });
        };

        $scope.postDone = function postDone() {
            $state.go('^.new', {}, { reload: true });
        };


        $scope.$on('okToSetPageDone', function (event) {

            pageService.pageIsDone({
                page_id: pageData.id,
                task_id: taskData.id
            })
                .then(function (response) {
                    //Reload current route
                    $state.go($state.current, {}, { reload: true });
                })
                .catch(function (err) {
                    console.log('Err', err);
                });
        });

        $scope.summarySelection = function (enabled) {
            $scope.showSelectionControls = enabled
        }
  
        $scope.toggleShareLink = function () {
            $scope.buildShareLink($scope.shareLinkId);
            $scope.showShareLink = !$scope.showShareLink;
        };

        $scope.saveAndDone = function saveAndDone() {
            try {
                $scope.save()
                    .then(function (response) {
                        if (response.status == 'ok') {
                            $scope.postDone();
                        }
                    });
            } catch (error) {
                console.log(error)
            }
        };

        /**
         * Create keyboard shortcuts for shifting between tabs in the wizard
         * 
         * Shift + Arrow Up     : Go one tab up/back
         * Shift + Arrow Down   : Go one tab down/forward
         * 
         */
        $scope.keyboardShortcuts = function keyboardShortcuts() {
            document.onkeydown = function (e) {
                //For cross-browser compability
                var key = e.which || e.keyCode;

                if (e.shiftKey && (key == 38 || key == 40)) {
                    e.preventDefault();
                }
            };
            document.onkeyup = function (e) {
                //For cross-browser compability
                var key = e.which || e.keyCode;

                if (e.shiftKey && key == 38) {
                    if ($scope.currentStep > 1) {
                        $scope.goToStep(parseInt($scope.currentStep) - 1);
                    }
                } else if (e.shiftKey && key == 40) {
                    if ($scope.currentStep < $scope.numSteps) {
                        $scope.goToStep(parseInt($scope.currentStep) + 1);
                    }
                }
            };
        }

        /**
         * Lookup the value of a specifik object path in the $scope.values object
         *
         * @param key {string|array} The path to the value
         *
         * @return The value found, or an empty string if none were found
         */
        $scope.lookupFieldValue = function lookupFieldValue(field) {
            return helpers.lookupFieldValue(field.key, field.schema, $scope.values);
        };

        /**
         * Delete value on the given key from the values object
         *
         * @param key {string|array} The path to the value
        * @param subkey {string} The property name to look for in an array type field
        * @param index {int} The index of the field, ie. the index of the field in the array object
        */
        $scope.removeFieldValue = function removeFieldValue(key, subkey, index) {

            //Path can be either a string, or an array
            var path = angular.isArray(key) ? key : key.split('.'),
                len = path.length;

            path.reduce(function (accumulator, currentValue, currentIndex, arr) {
                //Are we on the last iteration of the reduce?
                if (currentIndex === len - 1) {

                    if (subkey && index !== undefined) {
                        //Find the subkey, and delete it
                        if (subkey in accumulator[currentValue][index]) {
                            delete accumulator[currentValue][index][subkey]
                        }
                    }
                    else {
                        delete accumulator[currentValue];
                    }

                }
                return accumulator[currentValue];
            }, $scope.values);

            $scope.closeEditField();

        };

        /**
        * When postId has a value, build link to the id
        */
        $scope.$watch('shareLinkId', function (newVal) {
            if (newVal !== undefined) {
                var link = PERMALINK_URL + '/post/' + newVal;

                $scope.shareLink = link;
            }
        });

        /**
         * Load step data from the server
         */
        stepService.getData(taskData.id).then(function (response) {

            //The schema setup
            $scope.schema = response.schema;

            $scope.mainProperty = response.keyName;

            //TODO: Remove the check if backend removes the step, so that id 1 can be used once again
            //Test if Step id 1 exists, as it is skipped and handle stepdata accordingly
            if (response.steps.some(function (element) {
                return element.id === "1"
            })) {
                $scope.steps = response.steps.filter(function (element) {
                    return element.id !== "1";
                });
            } else {
                $scope.steps = response.steps;
            }

            //Take note of the total number of steps
            $scope.numSteps = $scope.steps.length;

            //Prepare the initial step data, so we can render the current step
            $scope.currentStepData = $scope.steps[$scope.currentStep - 1];

            $scope.summaryFields = helpers.prepareSummaryData(response.steps, response.schema, response.keyName);
        });

        $scope.init = function () {
            $timeout(function() {
                if ($state.params.stepId != 1) {
                    $state.go('.', { stepId: 1 })
                }

                $rootScope.$broadcast('zoom-to-selection');
                $scope.acceptArea();
                $scope.keyboardShortcuts();
            })
        };
    }
];