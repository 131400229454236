import angular from 'angular';
import { post } from 'jquery';

export default [
    '$uibModal',
    '$rootScope',
    '$scope',
    '$state',
    '$stateParams',
    '$timeout',
    '$transitions',
    'PERMALINK_URL',
    'taskData',
    'userData',
    'pageData',
    'unitData',
    'entryData',
    'errorReports',
    'stepService',
    'entryService',
    'errorService',
    'postService',
    'pageService',
    'helpers',
    'updateShare',
    updateFieldsController
]

function updateFieldsController (
    $uibModal,
    $rootScope,
    $scope,
    $state,
    $stateParams,
    $timeout,
    $transitions,
    PERMALINK_URL,
    taskData,
    userData,
    pageData,
    unitData,
    entryData,
    errorReports,
    stepService,
    entryService,
    errorService,
    postService,
    pageService,
    helpers,
    updateShare
) {
    $scope.allow_subposts = taskData.allow_subposts
    $scope.showSelectionControls = false
    $scope.summarySelection = function(enabled) {
        $scope.showSelectionControls = enabled
    }
    $scope.values = entryData;

    $scope.selectionState = false
    $scope.selectionSubpostIndex = undefined
    let prevZoomedPost = undefined
    $scope.editMainPost = function () {
        if ($scope.selectionState !== false) {
            $scope.cancelPostSelection()
        }
        prevZoomedPost = $scope.updateShare.zoomedPost
        $scope.updateShare.zoomedPost = undefined

        if ($scope.viewer.page.id != $scope.updateShare.main.pageData.id) {
            $scope.viewer.page = $scope.updateShare.main.pageData
        }

        broadcastPostOverlays();
        $scope.viewer.selectedPost = $scope.updateShare.main.area

        $scope.selectionState = 'editMainPost'
    }

    $scope.addSubpost = function () {
        if ($scope.selectionState !== false) {
            $scope.cancelPostSelection()
        }
        prevZoomedPost = $scope.updateShare.zoomedPost
        $scope.updateShare.zoomedPost = undefined
        $scope.selectionState = 'addSubpost'

        let selectedPost = undefined
        if ($scope.updateShare.subposts) {
            let currentPageSubposts = $scope.updateShare.subposts.filter(sp => sp.pageData.id == $scope.viewer.page.id)
            if (currentPageSubposts.length > 0) {
                selectedPost = currentPageSubposts[currentPageSubposts.length - 1]
            }
        }

        if (!selectedPost) {
            $scope.viewer.selectedPost = $scope.updateShare.main.area
        } else {
            $scope.viewer.selectedPost = selectedPost.area
        }
    }

    $scope.editSubpost = function (index) {
        if ($scope.selectionState !== false) {
            $scope.cancelPostSelection()
        }
        prevZoomedPost = $scope.updateShare.zoomedPost
        $scope.updateShare.zoomedPost = undefined

        let subpost = $scope.updateShare.subposts[index]
        if ($scope.viewer.page.id != subpost.pageData.id) {
            $scope.viewer.page = subpost.pageData
        }
        broadcastPostOverlays();
        $scope.viewer.selectedPost = subpost.area

        $scope.selectionState = 'editSubpost'
        $scope.selectionSubpostIndex = index
    }

    $scope.cancelPostSelection = function () {
        $scope.viewer.selectedPost = undefined
        $scope.selectionState = false
        $scope.selectionSubpostIndex = undefined
        $scope.updateShare.zoomedPost = prevZoomedPost
        broadcastPostOverlays();
    }

    $scope.removeSubpost = function (index) {
        let subpost = $scope.updateShare.subposts[index]

        let zoomedPostAfter
        if (prevZoomedPost && prevZoomedPost != subpost.area) {
            // Go to previous zoomed post
            zoomedPostAfter = prevZoomedPost
        } else if (index > 0) {
            // Find the previous subpost in the order of added
            zoomedPostAfter = $scope.updateShare.subposts[index - 1]
        } else {
            // Fall back to main area
            zoomedPostAfter = $scope.updateShare.main
        }

        postService.deleteSubpost(subpost.area.id)
        .then(function () {
            $scope.updateShare.subposts.splice(index, 1)
            // remove subpost from viewer page data
            $scope.viewer.page.subposts.splice($scope.viewer.page.subposts.findIndex(function (pageSubpost) { return pageSubpost.id == subpost.area.id }), 1)
            if ($scope.updateShare.zoomedPost == subpost) {
                // only change zoomed post if current is the delete subpost
                $scope.updateShare.zoomedPost = zoomedPostAfter
                prevZoomedPost = undefined // undefine prevZoomedPost as this one is removed
            }
            broadcastPostOverlays();
        })
    }

    $scope.acceptPost = function () {
        $rootScope.$broadcast('areaAccepted');
    }

        
    $scope.goToPageNumber = function (pageNumber) {
        if (pageNumber < 1 || pageNumber > unitData.pages || pageNumber == $scope.viewer.page.page_number) {
            return
        }

        pageService.getPageDataByNumber(taskData.id, pageNumber, unitData.id)
        .then(function(response) {
            if (!angular.isArray(response)) {
                $scope.updateShare.zoomedPost = undefined
                $scope.viewer.zoomedPost = undefined
                $scope.viewer.page = response
                broadcastPostOverlays();
            }
        });
    }

    /**
     * An area/post was selected by the image viewer.
     */
    $scope.$on('selectedPost', function (event, postData) {
        if ($scope.updateShare.main === undefined) {
            $scope.updateShare.main = postData
            $scope.viewer.zoomedPost = postData.area
            $scope.updateShare.zoomedPost = postData
        } else if ($scope.selectionState == 'editMainPost') {
            postService.update(taskData.id, angular.merge({page_id: postData.pageData.id}, postData.area), $state.params.postId)
                .then(function () {
                    let pagePostIndex = $scope.viewer.page.posts.findIndex(function (pagePost) {
                        return pagePost.id == $state.params.postId
                    })

                    // Update post area in page data, to prevent ghost posts
                    if (pagePostIndex != -1) {
                        $scope.viewer.page.posts[pagePostIndex] = postData
                    }

                    $scope.updateShare.main = postData
                    $scope.viewer.zoomedPost = postData.area
                    $scope.updateShare.zoomedPost = postData
                })
                .catch(function () {
                    $scope.cancelPostSelection()
                })
        } else if ($scope.selectionState === 'addSubpost') {
            postService.createSubposts(taskData.id, $state.params.postId, [angular.merge( {pages_id: postData.pageData.id}, postData.area )])
                .then(function (response) {
                    postData.area.id = response.subpost_ids[0]
                    $scope.updateShare.subposts.push(postData)
                    $scope.viewer.zoomedPost = postData.area
                    $scope.updateShare.zoomedPost = postData
                })
                .catch(function () {
                    $scope.cancelPostSelection()
                })
        } else if ($scope.selectionState === 'editSubpost') {
            let prevData = $scope.updateShare.subposts[$scope.selectionSubpostIndex]
            let mergedData = angular.merge(prevData, postData) // keep subpost id
            postService.updateSubpost(mergedData.area.id, mergedData.area)
                .then(function () {
                    $scope.updateShare.subposts[$scope.selectionSubpostIndex] = mergedData
                    $scope.selectionSubpostIndex = undefined
                    $scope.viewer.zoomedPost = mergedData.area
                    $scope.updateShare.zoomedPost = mergedData
                })
                .catch(function () {
                    $scope.cancelPostSelection()
                })
        }

        $scope.selectionState = false
    })

    //Get the postId, and store it on the values object, we need it to be able to save data
    $scope.values.post_id = $state.params.postId;

    $rootScope.$broadcast('zoom-to-post', { postId: $state.params.postId });

    //Build a direct link to this post
    $scope.shareLink = PERMALINK_URL + '/post/' + taskData.collection_id + '-' + entryData.concrete_entries_id;

    $scope.errorReports = errorReports;

    // Initialize viewer
    $scope.updateShare = updateShare
    $scope.viewer.selectedPost = undefined
    $scope.viewer.showEditPost = false

    /**
     * The zoomed post has been selected on the wizard footer
     */
    $scope.$watch('updateShare.zoomedPost', function (newVal, oldVal) {
        if ($scope.updateShare.zoomedPost) {
            $scope.viewer.page = $scope.updateShare.zoomedPost.pageData
            $scope.viewer.zoomedPost = $scope.updateShare.zoomedPost.area
        } else {
            $scope.viewer.zoomedPost = undefined
        }
        broadcastPostOverlays();
    })
    
    function broadcastPostOverlays() {
        $timeout(function () {
            let postOverlaysData = { subposts: [] }

            if ($scope.updateShare.main != $scope.updateShare.zoomedPost) {
                postOverlaysData.main = $scope.updateShare.main
            }

            for (let subpost of $scope.updateShare.subposts) {
                if (subpost != $scope.updateShare.zoomedPost) {
                    postOverlaysData.subposts.push(subpost)
                }
            }

            $rootScope.$broadcast('postOverlays', postOverlaysData)
        }, 200)
    }

    

    /**
    * Update a given error report
    * @params {int} id The error report id
    * @params {reason} string The reason for the update
    */
    $scope.updateErrorReport = function(report, reason) {
        reason = reason || "No reason given";

        report.deleted = 1;
        report.deleted_reason = reason;
    }

    /**
    * Delete a value from the value object
    * ie. the user does not think the saved value should be there at all
    */
    $scope.removeFieldValue = function removeFieldValue(field) {
        var split = angular.isArray(field.key) ? angular.copy(field.key) : angular.copy(field.key).split('.');
        
        if (split[0] === $scope.mainProperty) {
            split.shift();
        }

        if (split.length > 1) {
            $scope.values[$scope.mainProperty][split[0]][split[1]] = $scope.values[$scope.mainProperty][split[0]][split[1]].constructor === Array ? [] : null;
        }
        else {
            $scope.values[$scope.mainProperty][split[0]] = $scope.values[$scope.mainProperty][split[0]].constructor === Array ? [] : null;
        }

        $scope.toggleEdit(field);
    }

    /**
     * Put a value into the correct place in the given model.
     * 
     * @param {array} keySegments The parts of the key.
     * @param {object} model The value model for the schema.
     * @param {any} value The value to be inserted into the model.
     */
    function putValue(keySegments, model, value) {
        if (keySegments.length > 1) {
            let nextKey = angular.copy(keySegments)
            nextKey.shift()
            if (!model[keySegments[0]]) {
                model[keySegments[0]] = {}
            }
            putValue(nextKey, model[keySegments[0]], value)
        } else if (keySegments.length == 1) {
            model[keySegments[0]] = value
        }
    }

    /**
     * Get a value from the correct place in the given model.
     * 
     * @param {array} keySegments The parts of the key.
     * @param {object} model The value model to put the value into.
     * @param {boolean} fullStructure Whether to return the full structure, eg. { keyPart1: { keyPart2: value } }
     */
    function getValue(keySegments, model, fullStructure) {
        if (keySegments.length > 1) {
            let nextKey = angular.copy(keySegments)
            nextKey.shift()
            if (fullStructure) {
                let struc = {}
                if (model) {
                    struc[keySegments[0]] = getValue(nextKey, model[keySegments[0]], fullStructure)
                } else {
                    model = {}
                    model[keySegments[0]] = null
                    struc[keySegments[0]] = getValue(nextKey, model, fullStructure)
                }
                return struc
            } else {
                return getValue(nextKey, model[keySegments[0]], fullStructure)
            }
        } else if (keySegments.length == 1) {
            if (fullStructure) {
                let struc = {}
                struc[keySegments[0]] = model ? model[keySegments[0]] : null
                return struc
            } else {
                return model[keySegments[0]]
            }
        }
    }

    /**
    * Accept the edits made in a summary field.
    * 
    * Copy the temporary value when editing a field to the actual values object
    */
    $scope.acceptEdit = function acceptEdit(field) {
        let key = angular.isString(field.key) ? field.key.split('.') : field.key
        let value = getValue(key, field.editValue, false)
        putValue(key, $scope.values, value)
        $scope.toggleEdit(field);
    }

    $scope.userId = userData.apacs_user_id;

    //Default settings for angular-schema-forms
    $scope.sfDefaults = {
        formDefaults: {
            feedback: false,
            supressPropertyTitles: false,
            disableSuccessState: true,
            ngModelOptions: {},
            //Default value for array add action
            add: 'Tilføj'
        }
    };

    /**
     * When a schemaform is rendered, we set up event handlers to trigger submit on
     * enter key down, and we focus the form input element.
     */
    $scope.$on('sf-render-finished', function(event, schemaform) {
        $timeout(function() {
            // find relevant element -- input elements are handled natively
            let element = $(schemaform).find('.ui-select-focusser');
            if (element.length == 0) {
                element = $(schemaform).find('select');
            }

            // trigger submit on enter key down in order to use the event
            // handler set in the template
            if (element.length != 0) {
                element.on('keydown', function (event) { 
                    if (event.keyCode == 13) {
                        $(schemaform).trigger('submit');
                    }
                });
            }

            // set focus on relevant element - here we include input elements
            if (element.length == 0) {
                element = $(schemaform).find('input').first();
            }
            
            element.first().focus();
        });
    });

    function initialValueForSchema(schema) {
        switch (schema.type) {
            case 'object':
                let value = {}
                for (let prop in schema.properties) {
                    value[prop] = initialValueForSchema(schema.properties[prop])
                }
                return value;
            case 'array':
                return [];
            default:
                return null;
        }
    }

    /**
     * Toggle wether or not we should show edit field for a given field config
     */
    $scope.toggleEdit = function (field) {
        if (!field.isEditing) {
            // Toggle on: If the field is not being edited, set the edit status and copy the value from
            // the model with a deep copy into the temporary edit values.
            let key = angular.isString(field.key) ? field.key.split('.') : field.key
            field.editValue = angular.copy(getValue(key, $scope.values, true))

            if (getValue(key, field.editValue, false) == null) {
                putValue(key, field.editValue, initialValueForSchema(field.schema))
            }

            field.isEditing = true;

            // Remove active array item property edits, to prevent mismatch
            if (field.itemPropEdits != undefined) {
                delete field.itemPropEdits;
            }
        } else {
            // Toggle off: If the field is being edited, set is as not being edited, and delete the temporary 
            // edit value.
            field.editValue = null
            field.isEditing = false;
        }
    };

    $scope.toggleEditItemProperty = function (field, item, prop) {
        let parentKey = angular.copy(field.key)
        parentKey = angular.isString(parentKey) ? parentKey.split('.') : parentKey
        let parentValue = getValue(parentKey, $scope.values, false)
        let index = parentValue.indexOf(item)

        if (field.itemPropEdits == undefined) {
            field.itemPropEdits = {};
        }

        if (index in field.itemPropEdits && prop in field.itemPropEdits[index]) {
            delete field.itemPropEdits[index][prop]
            if (Object.keys(field.itemPropEdits[index]).length == 0) {
                delete field.itemPropEdits[index];
            }
        } else {
            if (!(index in field.itemPropEdits)) {
                field.itemPropEdits = {}
                field.itemPropEdits[index] = {}
            }

            let valueCopy = {};
            valueCopy[prop] = angular.copy(parentValue[index][prop])
            let key = (angular.isArray(field.key) ? field.key.join(".") : field.key) + "[]." + prop;
            let form
            if (field.items) {
                form = angular.copy(field.items.find(field => angular.isString(field) && field == key || field.key == key))
            } else {
                form = key
            }
            if (angular.isObject(form) && taskData.id != 6) {
                // It is uncertain if this is used at all, but it breaks task 6 so we are skipping it for now.
                let formItems = [form]
                while (formItems.length > 0) {
                    let fi = formItems.shift()
                    fi.key = fi.key.replace((angular.isArray(field.key) ? field.key.join(".") : field.key) + "[].", "")
                    if (angular.isArray(fi.items)) {
                        for (let c of fi.items) {
                            formItems.push(c)
                        }
                    }
                }
                console.log(JSON.stringify(field, item, prop, formItems));
                form.key = prop;
            } else {
                form = prop;
            }

            let schema = { type: "object", properties: {} }
            schema.properties[prop] = field.schema.items.properties[prop]

            field.itemPropEdits[index][prop] = {
                value: valueCopy,
                schema: schema,
                form: [form]
            }
        }
    }

    /**
     * Create a display value for a given value of a given property in an array item.
     * @param {object} parentSchema The schema of the array itself.
     * @param {any} parentForm The form of the array itself.
     * @param {string} prop The property of the item in the array.
     * @param {any} value The value of the property of the item in the array.
     * @returns A display value.
     */
    $scope.arrayItemDisplayValue = function (parentSchema, parentForm, prop, value) {
        // Check for titleMapped properties
        if (angular.isObject(parentForm) && parentForm.items) {
            let form = parentForm.items.find(function (item) {
                return item.key == parentForm.key + "[]." + prop
            })
            if (form && form.titleMap) {
                let code = form.titleMap.find(function (item) {
                    return item.value == value
                })
                if (code && code.name) {
                    return code.name
                }
            }
        }

        // Without a schema, just return the value directly.
        let schema = parentSchema.items.properties[prop]
        if (!schema) {
            return value
        }

        if (angular.isArray(schema.type)) {
            // Handle uniony types
            if (schema.type.includes('boolean')) {
                return value ? 'Ja' : 'Nej'
            } else if (schema.type.includes('string')) {

            }
        } else {
            // Return values of simple types directly
            return value
        }
    }

    $scope.acceptItemProperty = function (field, item, prop) {
        let parentKey = angular.copy(field.key)
        parentKey = angular.isString(parentKey) ? parentKey.split('.') : parentKey
        let parentValue = getValue(parentKey, $scope.values, false)
        let index = parentValue.indexOf(item)

        parentValue[index][prop] = field.itemPropEdits[index][prop].value[prop];
        $scope.toggleEditItemProperty(field, item, prop);
    }

    $scope.removeItemProperty = function (field, item, prop) {
        let parentKey = angular.copy(field.key)
        parentKey = angular.isString(parentKey) ? parentKey.split('.') : parentKey
        let parentValue = getValue(parentKey, $scope.values, false)
        let index = parentValue.indexOf(item)

        parentValue[index][prop] = null;
        $scope.toggleEditItemProperty(field, item, prop);
    }

    /**
    * Build a string, representing the value of an array of fields
    * Will not take fields with null value / no value into account
    *
    * @param data {object}
    * @param prop {string}
    *
    * @return {string} The string represenation of all values, seperated by comma
    */
    $scope.getObjectStringRepresentation = function(data, schema) {
        return helpers.getObjectStringRepresentation(data, schema)
    };


    $scope.enableOverlayResize = function enableOverlayResize() {
        $rootScope.$broadcast('selectExistingOverlay', { postId: $state.params.postId })
    }

    /**
    * Update the post in the backend
    * When post is saved, update error report data on the backend in a seperate request
    */
    $scope.updatePost = function() {
        $scope.saving = true;

        //Update the post with new values
        entryService.updateEntry(entryData.id, $scope.values)
            .then(function(response) {
                // Update error reports to indicate changes (deleted, fixed)
                if ($scope.errorReports.length > 0){
                    return errorService.editErrorReports($scope.errorReports);
                }
            })
            .then(function() {
                // Go to the success state
                $state.transitionTo($state.current.name + ".done", $stateParams, { 
                    reload: true, inherit: false, notify: true
                  });
            })
            .catch(function(err) {

                $scope.error = err;

                $uibModal.open({

                    template: require('../error.modal.tpl.html'),
                    //The type of modal. The error modal makes more room for the error text
                    windowClass: 'modal--error',

                    //Make wizard scope available to the modal
                    scope: $scope,

                    controller: ['$scope', function($scope) {
                        $scope.dismiss = function() {
                            $scope.$dismiss();
                        };
                    }]
                });
            })
            .finally(function() {
                $scope.saving = false;
            });
    };

    $scope.lookupFieldValue = function lookupFieldValue(field) {
        return helpers.lookupFieldValue(field.key, field.schema, $scope.values);
    };

    /**
     * Load step data from the server
     */
    stepService.getData(taskData.id).then(function(response) {
        //The schema setup
        $scope.schema = response.schema;

        $scope.mainProperty = response.keyName;

        //Prepare data to render out fields in the correct order, ie. the order they are in the task step configuration
        $scope.summaryFields = helpers.prepareSummaryData(response.steps, response.schema, response.keyName);
    });

    // Hook for transitions moving away from this state, to clean up page controller state
    $transitions.onStart({
        from: 'taskunit.page.post',
        to: function (state) { return state.name != 'taskunit.page.post' }
    }, function () {
        $scope.viewer.selectedPost = pageData.task_page.is_done === 1 ? false : pageData.next_post
        $scope.viewer.page = pageData
        $scope.viewer.zoomedPost = null
        $scope.viewer.showEditPost = true
        $rootScope.$broadcast('resetOverlays');
    })
}