import ang from 'angular'
import OpenSeadragon from 'openseadragon'
import osdSelection from 'openseadragonselection'
import filtering from 'openseadragon-filtering'
import imgagingHelper from '@openseadragon-imaging/openseadragon-imaginghelper'
import hookHelper from '@openseadragon-imaging/openseadragon-viewerinputhook'
import angular from 'angular'

"use strict";

/**
 * Based on https://github.com/MaitreDede/angular-openseadragon
 */
export default ['helpers', function(helpers) {
    return {
        restrict: "E",

        scope: {
            page: '=',
            selectedPost: '=',
            zoomedPost: '=',
            showEditPost: '='
        },

        controller: ['API_URL', '$scope', '$compile', '$element', '$rootScope', '$timeout', function(API_URL, $scope, $compile, $element, $rootScope, $timeout) {
            let viewer, // Reference to the viewer instance
                selection, // Reference to the selection plugin instance
                tracker, // Reference to a key tracker (keyboard shortcuts)
                selectionRect, // Reference to the selection currently active (if any)
                loading = true,
                pendingAcceptSelection = false

            // https://github.com/openseadragon/openseadragon/issues/759

            // Compile the directive
            var template = $compile(require('./imageViewer.directive.tpl.html'))($scope);

            // Add template to the dom
            angular.element($element).replaceWith(template);

            function addPostOverlay(post, editPostId, subpostId = null, newEntry = false) {
                let localScope = $rootScope.$new();
                localScope.canEdit = post.user_can_edit;
                localScope.postId = editPostId;
                localScope.subpostId = subpostId;
                localScope.newEntry = newEntry;

                let elm = '<image-viewer-overlay post-id="postId" subpost-id="subpostId" can-edit="canEdit" new-entry="newEntry"></image-viewer-overlay>';
                elm = $compile(elm)(localScope);

				//https://openseadragon.github.io/docs/OpenSeadragon.Viewer.html
                //Convert values from backend to values OpenSeadragon can use
                let overlayRect = helpers.convertPercentToOpenSeadragonRect(post, imagingHelper.imgAspectRatio);
                //Prepare a new rect object to be added in the overlay
                overlayRect = new OpenSeadragon.Rect(overlayRect.x, overlayRect.y, overlayRect.width, overlayRect.height);

                //Add the overlay
                viewer.addOverlay({
                    element: elm[0],
                    location: overlayRect
                });
            }

            // Customize error message
            OpenSeadragon.setString('Errors.OpenFailed', 'Der opstod en fejl under åbning af billedet. Prøv igen senere!');

            // Initialize the viewer
            viewer = OpenSeadragon({
                // Turn off default buttons on the viewer, as we dont need those
                showNavigator: false,
                showHomeControl: false,
                showFullPageControl: false,
                showZoomControl: false,

                element: angular.element('.target')[0],

                showNavigator:  true,

                navigatorId: 'editor__navigator',

                maxZoomPixelRatio: 4,

                gestureSettingsMouse: {
                    scrollToZoom: true,
                    clickToZoom: true,
                    pinchToZoom: false

                },

                gestureSettingsTouch: {
                    scrollToZoom: true,
                    clickToZoom: true,
                    pinchToZoom: true
                },

                debugMode: false,
            })

            var imagingHelper = new OpenSeadragonImaging.ImagingHelper({
                viewer: viewer
            })

            viewer.addViewerInputHook({ 
                hooks: [{
                    tracker: 'viewer',
                    handler: 'clickHandler',
                    hookHandler: onViewerClick
                }]
            });

            $scope.$on('editor-zoom-in', function() {
                if (viewer) {
                    viewer.viewport.zoomBy(1.2)
                }                 
            })

            $scope.$on('editor-zoom-out', function() {
                if (viewer) {
                    viewer.viewport.zoomBy(0.8)
                }
            })

            function onViewerClick(event) {
                var isPostOverlay = $(event.originalEvent.target).parent().hasClass('imageViewer__can-edit');

                if (isPostOverlay) {
                    event.stopHandlers = true;
                    event.preventDefaultAction = true;
                    event.stopBubbling = false;
                }
            }

            function viewerOpen() {
                loading = true
                viewer.open({
                    tileSource: {
                        type: 'image',
                        url: API_URL + '/file/' + $scope.page.id,
                        navigatorPosition: 'TOP_LEFT'
                    },
                    width: 1
                })
            }

            //Listen for when the image has loaded, and then add any deferredOverlays found in the options
            viewer.addHandler('tile-loaded', function() {
                $timeout(function() {
                    if ($scope.zoomedPost || !$scope.showEditPost) {
                        return
                    }

                    for (let post of $scope.page.posts) {
                        addPostOverlay(post, post.id, null, !post.concrete_entries_id)
                    }

                    for (let subpost of $scope.page.subposts) {
                        addPostOverlay(subpost, subpost.posts_id, subpost.id)
                    }
                });
            });

            $scope.$watch('page', function() {
                viewerOpen()
            })

            function onZoomedPost(area) {
                if (!area || loading) {
                    return
                }

                for (let elem of $('.imageViewer__zoomed')) {
                    viewer.removeOverlay(elem)
                    elem.remove()
                }

                for (let elem of $('image-viewer-overlay')) {
                    viewer.removeOverlay(elem)
                    elem.remove()
                }

                let convertedArea = helpers.convertPercentToOpenSeadragonRect(area, imagingHelper.imgAspectRatio);
                let rect = new OpenSeadragon.Rect(convertedArea.x, convertedArea.y, convertedArea.width, convertedArea.height)
                
                viewer.addOverlay({
                    element: $('<div class="imageViewer__zoomed"></div>')[0],
                    location: rect
                });

                // Zoom viewer to the selected area
                viewer.viewport.fitBounds(rect, true);
            }

            $scope.$watch('zoomedPost', function(area) {
                onZoomedPost(area)
            })

            viewer.addHandler('open', function() {
                loading = false

                if ($scope.selectedPost) {
                    var convertedRect = helpers.convertPercentToOpenSeadragonRect($scope.selectedPost, imagingHelper.imgAspectRatio);
                    selectionRect = new OpenSeadragon.SelectionRect(convertedRect.x, convertedRect.y, convertedRect.width, convertedRect.height);
                    selection.rect = selectionRect;
                    selection.draw();
                    if (pendingAcceptSelection) {
                        pendingAcceptSelection = false
                        selection.confirm()
                    }

                    // When the directive is initialized, make sure we listen for key events on the selection area
                    createKeyTracker();
                }

                if ($scope.zoomedPost) {
                    onZoomedPost($scope.zoomedPost)
                }
            })

            //Event fired when selection is confirmed
            viewer.addHandler('selection', function (data) {
                var convertedRect = viewer.viewport.imageToViewportRectangle(data);

                //Convert given rect height value to percentage, as that is what the backend needs
                //Backend stores values in 1:1 aspect ratio, whereas the rect given here, is based on the width of the image
                // @see https://openseadragon.github.io/examples/viewport-coordinates/
                convertedRect = helpers.convertOpenSeadragonRectToPercent(convertedRect, imagingHelper.imgAspectRatio);

                $scope.selectedPost = undefined
                $scope.$emit('selection', convertedRect)
            });

            $scope.$watch('selectedPost', function () {
                if (!$scope.selectedPost || loading) {
                    selection.undraw()
                    return
                }

                for (let elem of $('.imageViewer__zoomed')) {
                    viewer.removeOverlay(elem);
                }

                var convertedRect = helpers.convertPercentToOpenSeadragonRect($scope.selectedPost, imagingHelper.imgAspectRatio);
                selectionRect = new OpenSeadragon.SelectionRect(convertedRect.x, convertedRect.y, convertedRect.width, convertedRect.height);
                selection.rect = selectionRect;
                selection.draw();

                viewer.viewport.fitVertically(true);

                //Setup custom key tracker for moving/resizing the selection
                createKeyTracker();

                viewer.canvas.focus();
            })

            $scope.$on('areaAccepted', function () {
                if (loading) {
                    pendingAcceptSelection = true
                } else {
                    selection.confirm();
                }
            });

            $scope.$on('areaCancelled', function () {
                selection.undraw();
            })

			// broadcast from updateFields controller
            $rootScope.$on('selectExistingOverlay', function(event, data) {
                var rect = {};

                //Filter out the overlay that match the postId given, and prepare the rest to be added to the viewer
                otherOverlays = $scope.overlays.filter(function(item, index) {
                    if (item.postId === data.postId) {
                        rect = item;
                        return false;
                    }
                    else {
                        return true;
                    }

                });

                //Timeout needed to let normal logic kick in, before trying to mess with the overlays and the viewer
                $timeout(function() {
                    //Remove all existing overlays
                    viewer.clearOverlays();

                    //Loop over all other overlays
                    for (let overlay of otherOverlays) {
                        var overlayRect;

                        var localScope = $rootScope.$new();

                        localScope.canEdit = overlay.canEdit;
                        localScope.postId = overlay.postId;

                        var elm = '<image-viewer-overlay post-id="postId" can-edit="false">';

                        elm = $compile(elm)(localScope);

                        //Convert values from backend to values OpenSeadragon can use
                        overlayRect = helpers.convertPercentToOpenSeadragonRect(overlay, imagingHelper.imgAspectRatio);

                        //Prepare a new rect object to be added in the overlay
                        overlayRect = new OpenSeadragon.Rect(overlayRect.x, overlayRect.y, overlayRect.width, overlayRect.height);

                        //Add the overlay
                        viewer.addOverlay({
                            element: elm[0],
                            location: overlayRect
                        });
                    }
                }, 600);
            });

			// broadcast from wizard/updateFields controllers
            $scope.$on('postOverlays', function (event, postOverlayData) {    
                for (let elem of $('.imageViewer__overlay')) {
                    viewer.removeOverlay(elem)
                    elem.remove()
                }

                for (let post of $scope.page.posts) {
                    // Conditional is a hack for update fields
                    if (!$scope.zoomedPost || $scope.zoomedPost.id != post.id) {
                        let rect = helpers.convertPercentToOpenSeadragonRect(post, imagingHelper.imgAspectRatio);
                        rect = new OpenSeadragon.Rect(rect.x, rect.y, rect.width, rect.height);
                        viewer.addOverlay({
                            element: $('<div class="imageViewer__overlay imageViewer__overlay-other"></div>')[0],
                            location: rect
                        })
                    }
                }

                
                for (let subpost of $scope.page.subposts) {
                    // Skip this subpost when the zoomedPost is a subpost (ie. has posts_id property), and
                    // has the same id as this subpost
                    if ($scope.zoomedPost && $scope.zoomedPost.posts_id && $scope.zoomedPost.id == subpost.id) {
                        continue
                    }

                    // Skip subposts not on this page
                    if (subpost.pages_id != $scope.page.id) {
                        continue
                    }

                    // Skip subposts which are in the postOverlayData
                    if (postOverlayData.subposts.find(function (overlaySubpost) { return overlaySubpost.area.id == subpost.id })) {
                        continue
                    }

                    // Add the page subpost as an overlay
                    let rect = helpers.convertPercentToOpenSeadragonRect(subpost, imagingHelper.imgAspectRatio);
                    rect = new OpenSeadragon.Rect(rect.x, rect.y, rect.width, rect.height);
                    viewer.addOverlay({
                        element: $('<div class="imageViewer__overlay imageViewer__overlay-other"></div>')[0],
                        location: rect
                    })
                }

                if (postOverlayData.main && postOverlayData.main.pageData.id == $scope.page.id) {
                    let convertedArea = helpers.convertPercentToOpenSeadragonRect(postOverlayData.main.area, imagingHelper.imgAspectRatio);
                    let convertedRect = new OpenSeadragon.Rect(convertedArea.x, convertedArea.y, convertedArea.width, convertedArea.height);
                    viewer.addOverlay({
                        element: $('<div class="imageViewer__overlay imageViewer__overlay-main"></div>')[0],
                        location: convertedRect
                    });
                }
            
                for (let subpost of postOverlayData.subposts) {
                    if (subpost.pageData.id != $scope.page.id) {
                        continue
                    }
                    let convertedArea = helpers.convertPercentToOpenSeadragonRect(subpost.area, imagingHelper.imgAspectRatio);
                    let convertedRect = new OpenSeadragon.Rect(convertedArea.x, convertedArea.y, convertedArea.width, convertedArea.height);
                    viewer.addOverlay({
                        element: $('<div class="imageViewer__overlay imageViewer__overlay-subpost"></div>')[0],
                        location: convertedRect
                    });
                }
            })

            $scope.$on('zoom-to-selection', function() {
                $timeout(function() {
                    if (selectionRect) {
                        viewer.viewport.fitBounds(selectionRect, true);
                    }
                });
            });

            $scope.$on('zoom-out', function() {
                $timeout(function() {
                    viewer.viewport.fitVertically(true);
                })
            })

            $scope.$on('resetOverlays', function () {
                viewer.clearOverlays()
                
                for (let post of $scope.page.posts) {
                    addPostOverlay(post, post.id)
                }

                for (let subpost of $scope.page.subposts) {
                    addPostOverlay(subpost, subpost.posts_id, subpost.id)
                }
            })

            var selectionConfig = {
                onSelection: function(rect) {
                    // Remove the custom key event handler
                    removeKeyTracker();
                },

                // disable all selection buttons
                toggleButton: null,
                showConfirmDenyButtons: false,
                showSelectionControl: false,
                styleConfirmDenyButtons: false
            };

            // Selection plugin
            // https://github.com/picturae/openseadragonselection
            selection = viewer.selection(selectionConfig);

                //Store default keyhandler, so that we can reenable it
            var tmp = viewer.innerTracker.keyDownHandler,
                //Array of pressed keys, indexed on keyCode
                map = [];

            /**
            * Create a custom tracker for key events, to control moving and resizing the selection area with
            * the keyboard
            */
            function createKeyTracker() {

                // Based on http://stackoverflow.com/questions/5203407/javascript-multiple-keys-pressed-at-once
                tracker = new OpenSeadragon.MouseTracker({

                    element: viewer.canvas,

                    keyUpHandler: function(event) {
                        //When a key is released, set its keyCode to false
                        map[event.keyCode] = false;
                    },

                    keyDownHandler: function(event) {

                        //When key is pressed, set its keycode to true in the array
                        map[event.keyCode] = true;

                        //q is not pressed
                        if (!map[81]) {
                            //Restore the saved default keyhandler
                            viewer.innerTracker.keyDownHandler = tmp;
                        }
                        //q is pressed
                        if (map[81]) {
                            //remove default key handler
                            viewer.innerTracker.keyDownHandler = null;
                        }

                        //Enter key
                        if (map[13]) {
                            selection.confirm();
                        }

                        //shift
                        if (map[16]) {
                            //left
                            if (map[81] && map[37]) {
                                selection.rect.width = selection.rect.width -= 0.005;
                                selection.draw();
                            }
                            //right
                            if (map[81] && map[39]) {
                                selection.rect.width = selection.rect.width += 0.005;
                                selection.draw();
                            }
                            //up
                            if (map[81] && map[38]) {
                                selection.rect.height = selection.rect.height -= 0.005;
                                selection.draw();
                            }
                            //down
                            if (map[81] && map[40]) {
                                selection.rect.height = selection.rect.height += 0.005;
                                selection.draw();
                            }

                        } else {
                            //left
                            if (map[81] && map[37]) {
                                selection.rect.x = selection.rect.x -= 0.005;
                                selection.draw();
                            }
                            //right
                            if (map[81] && map[39]) {
                                selection.rect.x = selection.rect.x += 0.005;
                                selection.draw();
                            }
                            //up
                            if (map[81] && map[38]) {
                                selection.rect.y = selection.rect.y -= 0.005;
                                selection.draw();
                            }
                            //down
                            if (map[81] && map[40]) {
                                selection.rect.y = selection.rect.y += 0.005;
                                selection.draw();
                            }
                        }

                    }
                });

            }

            /**
            * Remove the custom key tracker and cleanup
            */
            function removeKeyTracker () {
                if (tracker instanceof OpenSeadragon.MouseTracker) {
                    tracker.destroy();
                }
                map = [];
            }

            //Cleanup
            $scope.$on('destroy', function() {
                removeKeyTracker();
                viewer.destroy();
            });
        }]
    };
}];