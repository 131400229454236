function imageViewerOverlayDirective($stateParams) {
    return {
        restrict: 'E',

        scope: {
            canEdit: '=',
            postId: '=',
            subpostId: '=?',
            newEntry: '='
        },

        template: require('./imageViewerOverlay.directive.tpl.html'),

        link: function(scope, element, attrs) {
            scope.$watch('canEdit', function(newval, oldval) {
                scope.overlayClass = newval ? 'imageViewer__can-edit' : 'imageViewer__done';
            });
        }
    };
}

export default [
    '$stateParams',
    imageViewerOverlayDirective
];
