export default [
    "$stateProvider",
    "$urlRouterProvider",
    '$locationProvider',
    'IdleProvider',
    'IDLE_REFRESH_SECONDS',
    function editorConfig($stateProvider, $urlRouterProvider, $locationProvider, IdleProvider, IDLE_REFRESH_SECONDS) {
    // Set a timeout (used in pageNewController to force refresh)
    // for some reason, timeout does not fire unless idle does first,
    // so lets cheat a bit
    IdleProvider.idle(IDLE_REFRESH_SECONDS-1);
    IdleProvider.timeout(1);

    // For any unmatched url, redirect to /
    $urlRouterProvider.otherwise('/error');

    // override shared html5mode
    $locationProvider.hashPrefix('');
    $locationProvider.html5Mode({
        enabled: false,
        requireBase: false
    });

    // Now set up the states
    $stateProvider
        .state('handleCallback', {
            url: '/callback',
            resolve: {
                redirect: ['authService', '$location', function (authService) {
                    console.log("On callback, handling callback with auth service");
                    return authService.redirectOnCallback();
                }],
            }
        })
        .state('editor', {
            url: '/task/{taskId:int}/page/{pageId:int}',
            abstract: true,
            resolve: {
                /**
                 * Load page data and pass it to the controller
                 */
                pageData: ['$stateParams', 'pageService', 'unitService', '$q', function ($stateParams, pageService, unitService) {
                    return pageService.getPageById($stateParams.pageId, $stateParams.taskId)
                }]
            }
        })

        .state('editor.page', {
            url: '',
            redirectTo: function (trans) {
                return trans.injector().getAsync('pageData')
                    .then(function (pageData) {
                        return {
                            state: 'taskunit.page.new',
                            params: {
                                taskId: trans.params().taskId,
                                pageId: trans.params().pageId,
                                unitId: pageData.unit_id
                            }
                        }
                    })
            }
        })

        .state('editor.page.update', {
            url: '/post/{postId:int}',
            redirectTo: function (trans) {
                return trans.injector().getAsync('pageData')
                    .then(function (pageData) {
                        return {
                            state: 'taskunit.page.post',
                            params: {
                                taskId: trans.params().taskId,
                                pageId: trans.params().pageId,
                                postId: trans.params().postId,
                                unitId: pageData.unit_id
                            }
                        }
                    })
            }
        })

        .state('feedback', {

            url: '/feedback/{feedbackId:int}',
            template: require('./feedback/feedback.tpl.html'),
            controller: 'feedbackController'
        })

        .state('taskunit', {
            url: '/task/{taskId:int}/unit/{unitId:int}',
            abstract: true,
            template: require('./taskunit.tpl.html'),
            controller: 'taskunitController',
            resolve: {
                userData: ['authService', '$location', function (authService, $location) {
                    return authService.getUser(false, $location.absUrl());
                }],
                taskData: ['$transition$', 'taskService', '$q', function ($transition$, taskService, $q) {
                    return taskService.getTask($transition$.params().taskId)
                        .then(function (response) {
                            return response;
                        });
                }],
                unitData: ['$transition$', 'unitService', function($transition$, unitService) {
                    let unitId = $transition$.params().unitId
                    return unitService.getUnit(unitId)
                }],
                taskUnitData: ['$transition$', 'taskService', 'taskData', function ($transition$, taskService, taskData) {
                    let unitId = $transition$.params().unitId
                    return taskService.getUnits({ unit_id: unitId, task_id: taskData.id })
                        .then(function(taskUnits) {
                            if (taskUnits.length === 1) {
                                return taskUnits[0];
                            } else {
                                throw new Error('must be exactly one unit');
                            }
                        })
                }]
            }
        })

        .state('taskunit.page', {
            url: '/page/{pageId:int}',
            views: {
                'content@taskunit': {
                    template: require('./page.tpl.html'),
                    controller: 'pageController as $ctrl'
                },
                'footer@taskunit': {
                    template: require('./page.footer.tpl.html'),
                    controller: 'pageFooterController'
                }
            },
            abstract: true,
            resolve: {
                pageData: ['$transition$', 'pageService', 'taskData', function ($transition$, pageService, taskData) {
                    let pageId = $transition$.params().pageId,
                        taskId = taskData.id
                    return pageService.getPageById(pageId, taskId);
                }]
            }
        })

        .state('taskunit.page.new', {
            url: '',
            views: {
                'sidebar@taskunit.page': {
                    template: require('./page.new.tpl.html'),
                    controller: 'pageNewController'
                }
            },
            resolve: {
                isDone: ['pageData', function (pageData) {
                    if (pageData.task_page == undefined) {
                        throw new Error('Could not find task page');
                    } else {
                        return pageData.task_page.is_done === 1
                    }
                }]
            }
        })

        .state('taskunit.page.done', {
            url: '/done',
            views: {
                'sidebar@taskunit.page': {
                    template: require('./page.done.tpl.html'),
                    controller: 'pageDoneController'
                }
            }
        })

        .state('taskunit.page.wizard', {
            url: '/wizard/{stepId:int}',
            params: { stepId: { dynamic: true } },
            resolve: {
                wizardShare: [
                    '$q',
                    '$stateParams',
                    'pageService',
                    'postService',
                    'taskData',
                    'pageData',
                    function($q, $stateParams, pageService, postService, taskData, pageData) {
                        pageService.summonWizard($stateParams.taskId, pageData.id);

                        if (!taskData.pregenerated_posts || pageData.posts.length == 0) {
                            // HACK: find better solution than checking taskid
                            return {
                                main: undefined,
                                subposts: [],
                                zoomedPost: undefined,
                                pageNumber: pageData.page_number
                            }
                        }
                        // NOTE: duplicate of taskunit.page.post except for mainPost
                        let mainPost = pageData.posts[0];

                        let pageDataPromises = mainPost.subposts.map(function (subpost) {
                            return pageService.getPageById(subpost.pages_id, $stateParams.taskId)
                        })
                        pageDataPromises.unshift(pageService.getPageById(mainPost.pages_id, $stateParams.taskId))

                        return $q.all(pageDataPromises)
                        .then(function (pageDatas) {
                            let mainPageData = pageDatas.shift()

                            let main = {
                                area: mainPost,
                                pageData: mainPageData
                            }
                            
                            let subposts = pageDatas.map(function (promisePageData, index) {
                                return {
                                    area: mainPost.subposts[index],
                                    pageData: promisePageData
                                }
                            })

                            let zoomedPost = main
                            if ($stateParams.subpostId !== undefined) {
                                let subpost = subposts.find(function (subpost) {
                                    return subpost.area.id == $stateParams.subpostId
                                })

                                if (subpost) {
                                    zoomedPost = subpost;
                                }
                            } else if (mainPost.pages_id !== $stateParams.pageId) {
                                let subpost = subposts.find(function (subpost) {
                                    return subpost.pageData.id == $stateParams.pageId
                                })

                                if (subpost) {
                                    zoomedPost = subpost;
                                }
                            }

                            return {
                                main: main,
                                subposts: subposts,
                                zoomedPost: zoomedPost,
                                pageNumber: pageData.page_number
                            }
                        })
                    }
                ]
            },
            views: {
                'sidebar@taskunit.page': {
                    template: require('./wizard.tpl.html'),
                    controller: 'wizardController'
                },
                'footer@taskunit': {
                    template: require('./wizard.footer.tpl.html'),
                    controller: ['$state', '$scope', 'unitData', 'wizardShare', function($state, $scope, unitData, wizardShare) {
                        $scope.totalPages = unitData.pages
                        $scope.wizardShare = wizardShare
                        $scope.setZoomedPost = function(post) {
                            $scope.wizardShare.zoomedPost = post
                        }
                        $scope.cancelWizard = function() {
                            if (confirm('Vil du annullere indtastningen?')) {
                                $state.go('^.new')
                            }
                        }
                    }]
                }
            }
        })

        .state('taskunit.page.post', {
            url: '/post/{postId:int}?{subpostId:int}',
            views: {
                'sidebar@taskunit.page': {
                    template: require('./update/updateFields.tpl.html'),
                    controller: 'updateFieldsController',
                },
                'footer@taskunit': {
                    template: require('./update/updateFieldsFooter.tpl.html'),
                    controller: ['$state', '$scope', 'updateShare', function($state, $scope, updateShare) {
                        $scope.updateShare = updateShare
                        $scope.setZoomedPost = function(post) {
                            $scope.updateShare.zoomedPost = post
                        }
                        $scope.cancelWizard = function() {
                            if (confirm('Vil du annullere retningen?')) {
                                $state.go('^.new')
                            }
                        }
                    }]
                }
            },
            resolve: {
                postAreaData: [
                    '$stateParams',
                    'postService',
                    function ($stateParams, postService) {
                        return postService.areas($stateParams.postId)
                    }
                ],

                updateShare: [
                    '$q',
                    '$stateParams',
                    'pageService',
                    'postAreaData',
                    'pageData',
                    function($q, $stateParams, pageService, postAreaData, pageData) {
                        // NOTE: duplicate of taskunit.page.wizard except for mainPost
                        let mainPost = postAreaData

                        let pageDataPromises = mainPost.subposts.map(function (subpost) {
                            return pageService.getPageById(subpost.pages_id, $stateParams.taskId)
                        })
                        pageDataPromises.unshift(pageService.getPageById(mainPost.pages_id, $stateParams.taskId))

                        return $q.all(pageDataPromises)
                        .then(function (pageDatas) {
                            let mainPageData = pageDatas.shift()

                            let main = {
                                area: mainPost,
                                pageData: mainPageData
                            }
                            
                            let subposts = pageDatas.map(function (promisePageData, index) {
                                return {
                                    area: mainPost.subposts[index],
                                    pageData: promisePageData
                                }
                            })

                            let zoomedPost = main
                            if ($stateParams.subpostId !== undefined) {
                                let subpost = subposts.find(function (subpost) {
                                    return subpost.area.id == $stateParams.subpostId
                                })

                                if (subpost) {
                                    zoomedPost = subpost;
                                }
                            } else if (mainPost.pages_id !== $stateParams.pageId) {
                                let subpost = subposts.find(function (subpost) {
                                    return subpost.pageData.id == $stateParams.pageId
                                })

                                if (subpost) {
                                    zoomedPost = subpost;
                                }
                            }

                            return {
                                main: main,
                                subposts: subposts,
                                zoomedPost: zoomedPost,
                                pageNumber: pageData.page_number
                            }
                        })
                    }
                ],

                postData: [
                    '$stateParams',
                    'postService',
                    function ($stateParams, postService) {
                        return postService.getData($stateParams.postId)
                    }
                ],

                entryData: [
                    'entryService', 'postData',
                    function (entryService, postData) {
                        return entryService.getEntry(postData.data[0].entry_id)
                            .then(function (response) {
                                response.id = postData.data[0].entry_id
                                return response
                            })
                    }
                ],

                errorReports: [
                    '$stateParams',
                    'errorService',
                    function ($stateParams, errorService) {
                        return errorService.getErrorReports({
                            task_id: $stateParams.taskId,
                            post_id: $stateParams.postId
                        });
                    }
                ]
            }
        })

        .state('taskunit.page.post.done', {
            url: '/done',
            views: {
                'sidebar@taskunit.page': {
                    template: require('./update/updateFields.done.tpl.html'),
                    controller: 'updateFieldsDoneController'
                },
                'footer@taskunit': {
                    template: '<div class="page-details"><button class="btn btn-primary" ui-sref="^.^.new">Start ny indtastning</button></div>'
                }
            }
        })

        .state('error', {
            url: '/error',
            template: require('./error.tpl.html'),
            controller: ['ERROR_URL', function (ERROR_URL) {
                //Redirect to a page on KBH joomla
                //window.location.href = ERROR_URL;
            }]
        })

}];