import { Auth0Client } from '@auth0/auth0-spa-js';

var authService = ['$q', '$location', '$window', 'CALLBACK_URL', 'AUTH0_CLIENTID', 'AUTH0_DOMAIN', 'AUTH0_AUDIENCE', function authService($q, $location, $window, CALLBACK_URL, AUTH0_CLIENTID, AUTH0_DOMAIN, AUTH0_AUDIENCE) {

    const auth0 = new Auth0Client({
        clientId: AUTH0_CLIENTID,
        scope: 'openid email profile',
        domain: AUTH0_DOMAIN,
        authorizationParams: {
            audience: AUTH0_AUDIENCE
          }
    });

    return {
        redirectOnCallback(){
            auth0.handleRedirectCallback()
            .then(a => {
                if(a.appState && a.appState.target){
                    console.log("appState target received from Auth0, redirecting to " + a.appState.target);
                    $window.location.href = a.appState.target;
                }
                else{
                    console.log("callback received, but no target in app state: ", a);
                    var searchObject = $location.search();
                    console.log("redirecting to : ", searchObject['url']);
                    
                    $window.location.href = searchObject['url'];

                }
            });
        },
        getUser(allowEmpty, auth0RedirectUri) {
            auth0RedirectUri = CALLBACK_URL + "?url=" + $location.absUrl();
            return auth0.handleRedirectCallback()
            .then(a => {
                return auth0.getUser();
            })
            .catch(function() {
                console.log("Could not handle redirectCallback, trying getTokenSilently with no parameters");
                return auth0.getTokenSilently()
                .then(function() {
                    return auth0.getUser()
                });
            })
            .catch(function() {
                if (allowEmpty) {
                    return $q.resolve(null);
                }

                console.log("Could not get token silently, trying loginWithRedirect with url: " + auth0RedirectUri);
                
                auth0.loginWithRedirect({
                    authorizationParams : {
                        redirect_uri: auth0RedirectUri,
					    audience: AUTH0_AUDIENCE
                    }
                });

                return $q.resolve({});
            })
            .then(function(user) {
                if (user && user['https://kbharkiv.dk/claims/apacs_user_id']) {
                    user.apacs_user_id = user['https://kbharkiv.dk/claims/apacs_user_id'];
                    return $q.resolve(user);
                } else if (allowEmpty) {
                    return $q.resolve(null);
                } else {
                     $q.reject();
                }
            });
        },

        getToken() {
            var redirectUri = $location.protocol() + '://' + $location.host() + $location.url()
            if ($location.port() != 443 && $location.port() != 80) {
                redirectUri += ':' + $location.port();
            }

            redirectUri += "#/callback";

            return this.getTokenSilentlyOrRedirect( );
        },

        /*
            Returns a promise that resolves if a token can be obtained
            from Auth0. The method redirects the user to the login page
            if the token cannot be obtained.
        */
        getTokenSilentlyOrRedirect(targetUrl = $location.absUrl())
        {
            return new Promise((resolve, reject) => {
                var loginParams = {
                    authorizationParams : {
                        redirect_uri: CALLBACK_URL,
                        audience: AUTH0_AUDIENCE
                    },
                    appState: {
                        target: targetUrl,
                    }
                };
                
                auth0.getTokenSilently(loginParams)
                .catch(e => {
                    console.log("error when getting token silently: " + e);
                    console.log("could not get token silently, logging in with redirect");
                    auth0.loginWithRedirect(loginParams);
                })
                .then(r => {
                    console.log("token obtained from Auth0");
                    resolve(r);
                })
            });
        }
    };

}];

export default authService;