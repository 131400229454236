var pageNewController = [
    '$timeout',
    '$state',
    '$uibModal',
    '$scope',
    '$rootScope',
    'Idle',
    'taskData',
    'taskUnitData',
    'pageData',
    'isDone',
    'pageService',
    'IDLE_REFRESH_SECONDS',
    function($timeout, $state, $uibModal, $scope, $rootScope, Idle, taskData, taskUnitData, pageData, isDone, pageService, IDLE_REFRESH_SECONDS) {
        $scope.selectionImageUrl = require('../../../images/Cursor-Select-512.png').default;
        $scope.newPostsAllowed = true;
        $scope.taskID = 0;

        $scope.events = [];

        Idle.watch();

        // states: IdleStart / IdleWarn / IdleTimeout / IdleEnd
        $scope.$on('IdleTimeout', function() {
            $scope.idle_minutes = IDLE_REFRESH_SECONDS / 60;

            $uibModal.open({
                template: require('./reload.modal.tpl.html'),
                //The type of modal. The error modal makes more room for the error text
                windowClass: 'modal--error',

                //Make scope available to the modal
                scope: $scope,

                controller: ['$scope', function($scope) {
                    $scope.dismiss = function() {
                        $scope.skipToNext(-1); // hack to include current page in "skip to next"
                    };
                }]
            });
        });

        $scope.init = function() {
            $scope.taskID = taskData.id;
            $scope.pageIsAccessible = pageData.is_accessible;

            // If posts are not allowed, we indicate it in the sidebar (folkeregisterkort, vielser)
            if (taskData.pregenerated_posts) {
                $scope.newPostsAllowed = false;
            }
            
            if (isDone) {
                $timeout(function () {
                    $state.go('^.done', {
                        taskId: taskData.id,
                        pageId: pageData.id
                    });
                }, 0);
                return;
            }

            $rootScope.$broadcast('zoom-out');

            // if the posts match rows/cols AND either auto_mark_pages_done is configured (regular tasks) OR we only have one post which already has a concrete entry (folkeregister), we mark done and go next
            if (pageData.posts.length == taskUnitData.columns * taskUnitData.rows && (taskData.auto_mark_pages_done || (pageData.posts.length == 1 && pageData.posts[0].concrete_entries_id))) {
                // If the posts are filled, go to next page
                $scope.setPageDoneAndGotoNext();
            } else if (!pageData.is_accessible) {
                // Page is not accessible, skip to next
                $scope.skipToNext();
            } else if (!$scope.newPostsAllowed && (pageData.posts.length == 1 && !pageData.posts[0].user_can_edit)) {
                // User may not edit this post
                $scope.skipToNext();
            } else if ($scope.nextPost) {
                //Preselect button, based on if nextPost is available. 
                $timeout(function() {
                    $('#nextPost-button').focus();
                });
            }
        };
        

        /**
        * Set page to done, then skip to next page
        */
        $scope.setPageDoneAndGotoNext = function() {
            pageService.pageIsDone({
                page_id: pageData.id,
                task_id: taskData.id
            })
            .then($scope.skipToNext())
            .catch(function(err) {
                console.log('Error setting page as done: ', err);
            });
        };
        

        /**
        * Get next available page, based on unitId, taskId and the current page number
        */
        $scope.skipToNext = function(offset = 0) {
            pageService.getNextAvailablePage({
                task_id: taskData.id,
                unit_id: pageData.unit_id,
                current_number: pageData.page_number + offset
            })
            .then(function(response) {
                if(response.pages_id){
                    $timeout(function() {
                        if (response.pages_id == pageData.id) {
                            $state.go($state.current, {}, {reload: true});
                        } else {
                            $state.go('taskunit.page.new', { pageId: response.pages_id});
                        }
                    }, 0);
                } else {
                    $uibModal.open({

                        template: require('./missing.modal.tpl.html'),
                        //The type of modal. The error modal makes more room for the error text
                        windowClass: 'modal--error',

                        //Make wizard scope available to the modal
                        scope: $scope,

                        controller: ['$scope', function($scope) {
                            $scope.dismiss = function() {
                                $scope.$dismiss();
                            };
                        }]
                    });
                }
            })
            .catch(function(err) {
                console.log('Error skipping to next page: ', err);
            });
        };
    }
];

export default pageNewController;